import api from './api'
import TokenService from './tokenService'

class AuthService {
    async login(credentials) {
        let response = await api.post('/auth/login', credentials)
    
        if (response.data.accessToken) {
            TokenService.setUser(response.data)
        }
    
        return response.data
    }
    
    logout() {
        TokenService.removeUser()
    }
}

export default new AuthService()