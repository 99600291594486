import { ref } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import tokenService from '../services/tokenService'
import Login from '../views/Login'
import NetworkError from '../views/NetworkError'
import Users from '../views/Users'
import FileNotFound from '../views/FileNotFound'
import Projects from '../views/Projects'
import Project from '../views/Project'

const intendedRoute = ref(null)

const routes = [
    {
        path: '/',
        name: 'projects',
        component: Projects,
        meta: { requiresAuth: true },
    },
    {
        path: '/project/:id',
        name: 'project',
        component: Project,
        meta: { requiresAuth: true },
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/network_error',
        name: 'network_error',
        component: NetworkError,
    },
    {
        path: '/404',
        name: '404',
        component: FileNotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !isAuthenticated()) {
        // Save the intended route before redirecting to login
        console.log('saving route')
        intendedRoute.value = to
        next('/login')
    } else {
        next()
    }
})

export function getIntendedRoute() {
    return intendedRoute.value
}

export function clearIntendedRoute() {
    intendedRoute.value = null
}

function isAuthenticated() {
    const user = tokenService.getUser()
    return user != null
}

export default router
