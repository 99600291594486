<template>
    <div class="modal" id="editModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Redigera arbete</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="numberInput" class="form-label">Bokningsnummer</label>
                            <input id="numberInput" class="form-control" v-model="job.number" type="text" disabled="true"/>
                        </div>
                        <div class="mb-3">
                            <label for="commentInput" class="form-label">Kommentar</label>
                            <input id="commentInput" class="form-control" v-model="job.comment" type="text"/>
                        </div>
                        <div class="mb-3">
                            <label for="desciptionInput" class="form-label">Beskrivning (synlig för kund)</label>
                            <input id="desciptionInput" class="form-control" v-model="job.description" type="text"/>
                            <div class="text-danger" v-show="job.descriptionError">{{ job.descriptionError }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="addressInput" class="form-label">Platsadress</label>
                            <input id="addressInput" class="form-control" v-model="job.location_address" type="text"/>
                            <div class="text-danger" v-show="job.locationAddressError">{{ job.locationAddressError }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="cityInput" class="form-label">Platsort</label>
                            <input id="cityInput" class="form-control" v-model="job.location_city" type="text"/>
                            <div class="text-danger" v-show="job.locationCityError">{{ job.locationCityError }}</div>
                        </div>
                        <div class="card bg-light mt-3">
                            <div class="card-body">
                                <div class="mb-3">
                                    <label for="customerNameInput" class="form-label">Namn</label>
                                    <input id="customerNameInput" class="form-control" v-model="job.user_info.name" type="text"/>
                                    <div class="text-danger" v-show="job.nameError">{{ job.nameError }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="customerAddressInput" class="form-label">Adress</label>
                                    <input id="customerAddressInput" class="form-control" v-model="job.user_info.address" type="text"/>
                                    <div class="text-danger" v-show="job.addressError">{{ job.addressError }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="customerZipCodeInput" class="form-label">Postnummer</label>
                                    <input id="customerZipCodeInput" class="form-control" v-model="job.user_info.zip" type="text"/>
                                    <div class="text-danger" v-show="job.zipError">{{ job.zipError }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="customerCityInput" class="form-label">Postort</label>
                                    <input id="customerCityInput" class="form-control" v-model="job.user_info.city" type="text"/>
                                    <div class="text-danger" v-show="job.cityError">{{ job.cityError }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="customerEmailInput" class="form-label">e-postadress</label>
                                    <input id="customerEmailInput" class="form-control" v-model="job.user_info.email" type="text"/>
                                    <div class="text-danger" v-show="job.emailError">{{ job.emailError }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="customerPhoneInput" class="form-label">Telefonnummer</label>
                                    <input id="customerPhoneInput" class="form-control" v-model="job.user_info.phone" type="text"/>
                                    <div class="text-danger" v-show="job.phoneError">{{ job.phoneError }}</div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div v-show="job.errorMessage" class="text-danger">
                        {{ job.errorMessage }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Avbryt</button>
                    <button type="button" class="btn btn-primary" @click="onSubmit">Spara</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import projectsService from '@/services/projectsService'

const props = defineProps({
    job: Object
})

const emit = defineEmits([ 'close', 'reload' ])

async function onSubmit() {
    props.job.descriptionError = validateEmpty(props.job.description, 'Beskrivning')
    props.job.locationAddressError = validateEmpty(props.job.location_address, 'Platsadress')
    props.job.locationCityError = validateEmpty(props.job.location_city, 'Platsort')
    props.job.nameError = validateEmpty(props.job.user_info.name, 'Namn')
    props.job.addressError = validateEmpty(props.job.user_info.address, 'Adress')
    props.job.zipError = validateZip(props.job.user_info.zip)
    props.job.cityError = validateEmpty(props.job.user_info.city, 'Postort')
    props.job.emailError = validateEmail(props.job.user_info.email)
    props.job.phoneError = validatePhone(props.job.user_info.phone)

    if (props.job.descriptionError ||
        props.job.locationAddressError ||
        props.job.locationCityError ||
        props.job.nameError ||
        props.job.addressError ||
        props.job.zipError ||
        props.job.cityError ||
        props.job.emailError ||
        props.job.phoneError
    ) {
        return
    }

    projectsService.updateJob(props.job.id, {
        location_address: props.job.location_address,
        location_city: props.job.location_city,
        description: props.job.description,
        comment: props.job.comment,
        user_info: {
            name: props.job.user_info.name,
            address: props.job.user_info.address,
            zip: props.job.user_info.zip,
            city: props.job.user_info.city,
            email: props.job.user_info.email,
            phone: props.job.user_info.phone
        }
    }).then(_response => {
        emit('reload')
        emit('close')
    }).catch(error => {
        props.job.errorMessage = error.response.data.message
    })
}

function validateEmpty(value, field) {
    if (value == null || value.trim() === '') {
        return field + ' saknas'
    }
    return null
}

function validateZip(zip) {
    if (zip == null || zip.trim() === '') {
        return 'Postnummer saknas'
    }
    let count = 0
    for (let i = 0; i < zip.length; i++) {
        if (zip[i] === ' ') {
            continue
        } else if (isNaN(zip[i])) {
            return 'Postnummer innehåller ogiltiga tecken'
        }
        count++
    }
    return count === 5 ? null : 'Postnummer måste vara 5 siffror långt'
}

function validateEmail(email) {
    if (email == null || email.trim() === '') {
        return null
    }
    const re = /\S+@\S+\.\S+/
    return re.test(email) ? null : 'Ogiltig e-postadress'
}

function validatePhone(phone) {
    if (phone == null || phone.trim() === '') {
        return null
    }
    let count = 0
    for (let i = 0; i < phone.length; i++) {
        if (i === 0 && phone[i] === '+') {
            continue
        } else if (phone[i] === ' ' || phone[i] === '-') {
            continue
        } else if (isNaN(phone[i])) {
            return 'Telefonnummer inneåller ogiltiga tecken'
        }
        count++
    }
    return count >= 8 ? null : 'Telefonnummer måste vara minst 8 siffror långt'
}
</script>