import api from './api'

class ProjectsService {
    getProjects() {
        return api.get('/projects')
    }

    getProject(id) {
        return api.get(`/projects/${id}`)
    }

    getProjectStats(id) {
        return api.get(`/projects/${id}/stats`)
    }

    createProject(data) {
        return api.post('/projects', data)
    }

    updateProject(data) {
        console.log(data)
        return api.put(`/projects/${data.id}`, data)
    }

    deleteProject(id) {
        return api.delete(`/projects/${id}`)
    }

    getJobs(projectId, filter) {
        return api.get(`/projects/${projectId}/jobs`, { params: {
            page: filter.page,
            size: filter.size,
            search: filter.search,
            status: filter.status,
            timeWindowId: filter.timeWindowId,
            column: filter.column,
            dir: filter.dir
        }})
    }

    getJobsCount(projectId, filter) {
        return api.get(`/projects/${projectId}/jobs_count`, { params: { 
            search: filter.search, 
            status: filter.status, 
            timeWindowId: filter.timeWindowId
        }})
    }

    getJob(id) {
        return api.get(`/jobs/${id}`)
    }

    createJobs(projectId, data) {
        return api.post(`/projects/${projectId}/jobs`, data)
    }

    getTimeWindows(projectId, start, stop) {
        return api.get(`/projects/${projectId}/timewindows?start=${start.toISOString()}&stop=${stop.toISOString()}`)
    }

    getTimeWindowInfo(id) {
        return api.get(`/timewindows/${id}/info`)
    }

    createTimeWindows(projectId, data) {
        return api.post(`/projects/${projectId}/timewindows`, data)
    }

    updateTimeWindow(timeWindowId, totalNbrSpots) {
        return api.put(`/timewindows/${timeWindowId}`, { totalNbrSpots })
    }

    updateJobTimeWindow(jobId, timeWindowId, comment = '', resetStatus = false) {
        return api.put(`/jobs/${jobId}/timewindow`, { timeWindowId, comment, resetStatus })
    }

    deleteJob(id) {
        return api.delete(`/jobs/${id}`)
    }

    updateJobsTimeWindow(projectId, data) {
        return api.put(`/projects/${projectId}/book_jobs`, data)
    }

    getReport(projectId, reportName, params = {}) {
        return api.get(`/projects/${projectId}/reports/${reportName}`, { params })
    }

    updateJob(jobId, data) {
        return api.put(`/jobs/${jobId}`, data)
    }

    updateJobStatus(jobId, status, comment = '') {
        return api.put(`/jobs/${jobId}/status`, { status, comment })
    }

    getUnbookedJobs(projectId) {
        return api.get(`/projects/${projectId}/unbooked_jobs`)
    }

    getJobLog(jobId) {
        return api.get(`/jobs/${jobId}/log`)
    }
}

export default new ProjectsService()