<template>
    <div class="container mb-5">
        <div class="row border-start border-end border-bottom" style="background-color: white;">
            <div class="mt-3 row">
                <div class="col-3">
                    <input id="searchInput" class="form-control" v-model="jobs.searchString" type="text" placeholder="Nummer eller adress"/>
                </div>
                <div class="col-2">
                    <select id="statusSelect" class="form-select" v-model="jobs.statusFilter">
                        <option value="all">Alla status</option>
                        <option value="new">Ej bokat</option>
                        <option value="booked">Bokat</option>
                        <option value="ongoing">Pågående</option>
                        <option value="done">Klart</option>
                        <option value="failed_run">Bomkörning</option>
                        <option value="contact_me">Kontakta</option>
                    </select>
                </div>
                <div class="col-3">
                    <select id="windowSelect" class="form-select" v-model="jobs.timeWindowId">
                        <option value="">Alla tidsluckor</option>
                        <option v-for="window in jobs.timeWindows" :value="window.id">{{ new Date(window.date).toLocaleDateString('sv') }} {{ hours[window.index] }} ({{ window.nbr_booked_spots }} / {{ window.total_nbr_spots }})</option>
                    </select>
                </div>
                <div class="col-1">
                    <button class="btn btn-outline-primary" @click="$emit('reload')">Sök</button>
                </div>
                <div class="col-2"></div> 
                <div class="col-1">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" type="button" id="dropdownMenuButton">
                            Åtgärder
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                            <li><button class="btn btn-link dropdown-item" @click="showImportJobsModal">Importera jobb</button></li>
                            <li><button class="btn btn-link dropdown-item" @click="exportUnbookedJobs">Exportera obokade jobb</button></li>
                            <li><button class="btn btn-link dropdown-item" @click="showImportBookingsModal">Importera bokningar</button></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-2">
                    <button class="btn btn-link" @click="jobs.page--;$emit('reload')" :disabled="jobs.page == 1">&lt; Föregående</button>
                </div>
                <div class="col-2 mt-2">
                    Sida <select @change="$emit('reload')" v-model="jobs.page">
                        <option v-for="n of jobs.pages" :value="n" :selected="jobs.page == n">{{ n }}</option>
                    </select> av {{ jobs.pages }}
                </div>
                <div class="col-3 mt-2">
                    Visar {{ jobs.array.length }} av {{ jobs.count }} jobb
                </div>
                <div class="col-3 mt-2">
                    Sidstorlek
                    <select @change="$emit('reload')" v-model="jobs.size">
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
                <div class="col-2">
                    <button class="btn btn-link" @click="jobs.page++;$emit('reload')" :disabled="jobs.page >= jobs.pages">Nästa &gt;</button>
                </div>
            </div>
            <table class="table table-bordered" style="background-color: white;">
                <thead>
                    <tr>
                        <th><button :class="columnClasses.status" @click="sort('status')">Status</button></th>
                        <th><button :class="columnClasses.location_number" @click="sort('location_number')">Anl.nr.</button></th>
                        <th><button :class="columnClasses.location_address" @click="sort('location_address')">Plats</button></th>
                        <th><button :class="columnClasses.time_window" @click="sort('time_window')">Tid</button></th>
                        <td><div class="mb-1 text-muted">Kommentar</div></td>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="j in jobs.array" :key="j.id">
                        <td>
                            <span v-if="!j.time_window_id" class="badge bg-warning">Ej bokat</span>
                            <span v-else-if="j.status === 1" class="badge bg-primary">Bokat</span>
                            <span v-else-if="j.status === 2" class="badge bg-info">Pågående</span>
                            <span v-else-if="j.status === 3" class="badge bg-success">Klart</span>
                            <span v-else-if="j.status === 4" class="badge bg-danger">Bomkörning</span>
                            <span v-else-if="j.status === 5" class="badge bg-magenta">Kontakta</span>
                            <span v-else class="badge bg-secondary">Okänd</span>
                        </td>
                        <td>{{ j.location_number }}</td>
                        <td>{{ j.location_address }}</td>
                        <td :style="j.status == 1 && j.time_window_id && new Date(j.date) < today ? 'color: #f00' : ''">{{ j.time_window_id ? `${new Date(j.date).toLocaleDateString('sv')} ${hours[j.index]}` : 'Ej bokad'  }}</td>
                        <td>{{ j.comment }}</td>
                        <td class="text-end">
                            <button class="btn btn-link btn-sm" @click="showBookModal(j.id)">{{ j.time_window_id ? 'Boka om' : 'Boka' }}</button>
                            <button class="btn btn-link btn-sm" @click="showStatusModal(j.id)">Ändra status</button>
                            <button class="btn btn-link btn-sm" @click="showEditModal(j.id)">Redigera</button>
                            <button class="btn btn-link btn-sm" @click="showJobLog(j.id)">Logg</button>
                            <button class="btn btn-link btn-sm" @click="deleteJob(j.id)">Ta bort</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <ImportJobsModal id="importJobsModal" :data="importJobsData" @close="importJobsModal.hide()" @reload="$emit('reload')" />
    <EditJobModal id="jobModal" :job="job" @close="jobModal.hide()" @reload="$emit('reload')" />
    <BookModal id="bookModal" :data="booking" :windows="jobs.timeWindows" @close="bookModal.hide()" @reload="$emit('reload')" />
    <ImportBookingsModal id="importBookingsModal" :data="importBookingsData" @close="importBookingsModal.hide()" @reload="$emit('reload')" />
    <JobLogModal id="jobLogModal" :data="log" @close="jobLogModal.hide()" />
    <StatusModal id="statusModal" :job="job" @close="statusModal.hide()" @reload="$emit('reload')" />
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { Modal } from 'bootstrap'
import writeXlsxFile from 'write-excel-file'
import projectsService from '@/services/projectsService'
import ImportJobsModal from '@/components/ImportJobsModal.vue'
import EditJobModal from './EditJobModal.vue'
import BookModal from './BookModal.vue'
import ImportBookingsModal from '@/components/ImportBookingsModal.vue'
import JobLogModal from '@/components/JobLogModal.vue'
import StatusModal from '@/components/StatusModal.vue'

const hours = ref([ '07-10', '10-13', '13-16', '16-20' ])

const props = defineProps({
    project: Object,
    jobs: Object,
})

let importJobsModal = ref(null)
let jobModal = ref(null)
let bookModal = ref(null)
let importBookingsModal = ref(null)
let jobLogModal = ref(null)
let statusModal = ref(null)
const today = new Date().setHours(0, 0, 0, 0)

let columnClasses = {
    status: 'btn btn-link',
    location_number: 'btn btn-link headerSortDown',
    location_address: 'btn btn-link',
    time_window: 'btn btn-link',
}

const importJobsData = reactive({
    fileInputKey: 0,
    errorMessage: null,
    successMessage: null,
})

const importBookingsData = reactive({
    fileInputKey: 0,
    errorMessage: null,
    errorLines: [],
    successMessage: null,
})

const booking = reactive({
    jobId: null,
    cancelBooking: false,
    unbooked: false,
    comment: '',
    timeWindowId: null,
    errorMessage: null,
    resetStatus: false,
    status: null,
    originalTimeWindowId: null
})

const job = reactive({
    id: null,
    number: '',
    status: 1,
    description: '',
    location_address: '',
    location_city: '',
    time_window_id: null,
    user_info: {
        name: '',
        address: '',
        zip: '',
        city: '',
        email: '',
        phone: ''
    },
    comment: '',
    skipComment: false,
    descriptionError: null,
    locationAddressError: null,
    locationCityError: null,
    nameError: null,
    addressError: null,
    zipError: null,
    cityError: null,
    emailError: null,
    phoneError: null,
    originalStatus: 1,
    errorMessage: null
})

const log = reactive({
    entries: []
})

const emit = defineEmits([ 'reload' ])

function sort(col) {
    if (props.jobs.sortCol === col) {
        props.jobs.sortDir = props.jobs.sortDir === 'ASC' ? 'DESC' : 'ASC'
        columnClasses[col] = `btn btn-link headerSort${props.jobs.sortDir === 'ASC' ? 'Down' : 'Up'}`
    } else {
        columnClasses[props.jobs.sortCol] = 'btn btn-link'
        props.jobs.sortCol = col
        props.jobs.sortDir = 'ASC'
        columnClasses[col] = 'btn btn-link headerSortDown'
    }
    emit('reload')
}

async function exportUnbookedJobs() {
    const options = {
        fontFamily: 'Calibri',
        fontSize: 11,
        dateFormat: 'yyyy-mm-dd',
        headerStyle: {
            backgroundColor: '#c0c0c0',
            align: 'center',
            fontSize: 11,
            verticalAlign: 'middle',
        },
        schema: [
        {
                column: 'Bokningsnr.',
                type: String,
                width: 20,
                value: job => job.number
            },
            {
                column: 'Platsadress',
                type: String,
                width: 25,
                value: job => job.location_address
            },
            {
                column: 'Datum',
                type: String,
                width: 12,
                value: job => ''
            },
            {
                column: 'Lucka',
                type: String,
                width: 10,
                value: job => ''
            },
        ],
        fileName: 'Obokade jobb ' + props.project.name + ' ' + new Date().toLocaleDateString('sv-SE') + '.xlsx',
    }

    const jobs = (await projectsService.getUnbookedJobs(props.project.id)).data

    await writeXlsxFile(jobs, options)
}

function showImportJobsModal() {
    importJobsData.fileInputKey++
    importJobsData.errorMessage = null
    importJobsData.successMessage = null
    importJobsModal.show()
}

function showEditModal(id) {
    projectsService.getJob(id).then(response => {
        job.id = response.data.id
        job.time_window_id = response.data.time_window_id
        job.number = response.data.number
        job.comment = response.data.comment
        job.description = response.data.description
        job.location_address = response.data.location_address
        job.location_city = response.data.location_city
        job.user_info.name = response.data.user_info.name
        job.user_info.address = response.data.user_info.address
        job.user_info.zip = response.data.user_info.zip
        job.user_info.city = response.data.user_info.city
        job.user_info.email = response.data.user_info.email
        job.user_info.phone = response.data.user_info.phone
        job.errorMessage = null
        job.descriptionError = null
        job.locationAddressError = null
        job.locationCityError = null
        job.nameError = null
        job.addressError = null
        job.zipError = null
        job.cityError = null
        job.emailError = null
        job.phoneError = null
        jobModal.show()
    })
    .catch(error => {
        console.error(error)
    })
}

async function showStatusModal(id) {
    projectsService.getJob(id).then(response => {
        job.id = response.data.id
        job.status = response.data.status
        job.originalStatus = response.data.status
        job.errorMessage = null
        job.skipComment = false
        statusModal.show()
    })
    .catch(error => {
        console.error(error)
    })
}

async function showBookModal(id) {
    projectsService.getJob(id).then(response => {
        booking.jobId = response.data.id
        booking.timeWindowId = response.data.time_window_id
        booking.comment = ''
        booking.unbooked = response.data.time_window_id === null
        booking.cancelBooking = false
        booking.errorMessage = null
        booking.resetStatus = false
        booking.originalTimeWindowId = response.data.time_window_id
        booking.status = response.data.status
        bookModal.show()
    })
    .catch(error => {
        console.error(error)
    })
}

function showImportBookingsModal() {
    importBookingsData.fileInputKey++
    importBookingsData.errorMessage = null
    importBookingsData.errorLines = []
    importBookingsData.successMessage = null
    importBookingsModal.show()
}

async function deleteJob(id) {
    if (!confirm('Vill du verkligen ta bort jobbet?')) {
        return
    }
    projectsService.deleteJob(id).then(_response => {
        emit('reload')
    })
    .catch(error => {
        console.error(error)
    })
}

async function showJobLog(id) {
    const input = (await projectsService.getJobLog(id)).data
    const output = []
    for (let e of input) {
        const entry = {
            timestamp: e.timestamp,
            name: e.name,
            comment: e.info?.comment ?? '',
            expanded: false,
            data: []
        }
        if (e.info) {
            for (let item in e.info) {
                entry.data.push({
                    property: item,
                    value: e.info[item]
                })
            }
        }
        output.push(entry)
    }
    log.entries = output
    jobLogModal.show()
}

onMounted(() => {
    importJobsModal = new Modal(document.getElementById('importJobsModal'))
    jobModal = new Modal(document.getElementById('jobModal'))
    bookModal = new Modal(document.getElementById('bookModal'))
    importBookingsModal = new Modal(document.getElementById('importBookingsModal'))
    jobLogModal = new Modal(document.getElementById('jobLogModal'))
    statusModal = new Modal(document.getElementById('statusModal'))
})

onBeforeRouteLeave((to, from) => {
    importJobsModal.hide()
    jobModal.hide()
    bookModal.hide()
    importBookingsModal.hide()
    jobLogModal.hide()
    statusModal.hide()
})

</script>

<style scoped>
.headerSortDown:after,
.headerSortUp:after {
  content: ' ';
  position: relative;
  left: 2px;
  border: 8px solid transparent;
}

.headerSortDown:after {
  top: 10px;
  border-top-color: silver;
}

.headerSortUp:after {
  bottom: 15px;
  border-bottom-color: silver;
}

.headerSortDown,
.headerSortUp {
  padding-right: 10px;
}
</style>