<template>
    <div class="modal" id="editModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Redigera tidslucka</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="numberInput" class="form-label">Totala antalet tider</label>
                            <input id="numberInput" class="form-control" v-model="data.total" type="text"/>
                        </div>
                    </form>
                    <div v-show="data.errorMessage" class="text-danger">
                        {{ data.errorMessage }}
                    </div>
                    <div v-if="data.timeWindowId" class="card bg-light mt-3">
                        <div class="card-body">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>Bokade jobb</td>
                                        <td>{{ data.booked }}</td>
                                    </tr>
                                    <tr>
                                        <td>Pågående jobb</td>
                                        <td>{{ data.ongoing }}</td>
                                    </tr>
                                    <tr>
                                        <td>Utförda jobb</td>
                                        <td>{{ data.done }}</td>
                                    </tr>
                                    <tr>
                                        <td>Bomkörningar</td>
                                        <td>{{ data.missed }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="btn btn-link" @click="$emit('show-bookings', { timeWindowId: data.timeWindowId })">Gå till bokningar</button>
                        </div>
                    </div>
                    <div v-else class="alert alert-warning" role="alert">
                        Ingen tidslucka är inlagd för denna tid. Om du anger ett antal tider och sparar, så kommer en ny tidslucka att skapas.
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Avbryt</button>
                    <button type="button" class="btn btn-primary" @click="onSubmit">Spara</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import projectsService from '@/services/projectsService'

const emit = defineEmits([ 'close', 'reload', 'show-bookings' ])

const props = defineProps({
    data: Object
})

async function onSubmit() {
    let response
    
    if (props.data.timeWindowId === null) {
        const timeWindow = { 
            date: props.data.date, 
            index: props.data.index, 
            spots: props.data.total
        }
        response = await projectsService.createTimeWindows(props.data.projectId, [ timeWindow ])
    } else {
        response = await projectsService.updateTimeWindow(props.data.timeWindowId, props.data.total)
    }

    if (response.status === 200 || response.status === 201) {
        emit('reload')
        emit('close')
    } else {
        props.data.errorMessage = response.data.message
    }

}
</script>