<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-6">
                <h2>Projekt</h2>
            </div>
            <div class="col-3">
                <div class="form-check">
                    <input id="formErrors" type="checkbox" class="form-check-input" v-model="showArchived" @change="reload"/>
                    <label for="formErrors" class="form-label">Visa också arkiverade</label>
                </div>
            </div>
            <div class="col-3">
                <button class="btn btn-primary" v-if="isAdmin" @click="showEditModal(null)">Lägg till projekt</button>
            </div>
        </div>
        <div class="row">
            <table class="table table-bordered mt-3" style="background-color: white;">
                <thead>
                    <tr>
                        <th>Namn</th>
                        <th v-if="isAdmin"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="project in projects" :key="project.id">
                        <td>
                            <router-link :to="`/project/${project.id}`">{{ project.name }}</router-link>
                            <span v-if="showArchived" class="text-muted">{{ project.is_archived ? " (arkiverat)" : "" }}</span>
                        </td>
                        <td v-if="isAdmin" class="text-end">
                            <button class="btn btn-link btn-sm" @click="showEditModal(project.id)">Redigera</button>
                            <button class="btn btn-link btn-sm" @click="showDeleteModal(project.id)">Ta bort</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal" id="editModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ project.id ? 'Redigera projekt' : 'Lägg till projekt' }}</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click="editModal.hide()"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="mt-3">
                                <label for="nameInput" class="form-label">Namn</label>
                                <input type="text" class="form-control" id="nameInput" v-model="project.name">
                                <div class="text-danger" v-show="states.projectNameEmpty">Vänligen ange namn</div>
                                <div class="text-danger" v-show="states.projectNameTaken">Namnet används redan på en annan projekt</div>
                            </div>
                            <div class="mt-3">
                                <label for="descrInput" class="form-label">Beskrivning</label>
                                <input type="text" class="form-control" id="descrInput" v-model="project.description">
                            </div>
                            <div class="mt-3">
                                Egenskaper
                            </div>
                            <div class="card bg-light mt-2">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input id="showContactMeCheckbox" type="checkbox" class="form-check-input" v-model="project.contactMeCheckbox"/>
                                        <label for="showContactMeCheckbox" title="">Visa "kontakta mig"-kryssruta vid ombokning</label>&nbsp;
                                        <span class="text-muted" title="Visa kryssruta där den som bokar om begär att bli kontaktad. Man kan också ange den text som skall stå vid kryssrutan.">
                                            <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg>
                                        </span>
                                    </div>
                                    <div class="mt-2" v-show="project.contactMeCheckbox">
                                        <input type="text" class="form-control" id="contactMeInput" v-model="project.contactMeText" placeholder="Text vid kryssrutan">
                                    </div>
                                    <div class="text-danger" v-show="states.contaceMeTextEmpty">Vänligen ange en text</div>
                                </div>
                            </div>
                            <div class="form-check mt-3" v-show="project.id !== null">
                                <input id="showArchivedCheckBox" type="checkbox" class="form-check-input" v-model="project.is_archived"/>
                                <label for="showArchivedCheckBox" class="form-label">Arkiverat</label>
                            </div>
                        </form>
                        <div v-show="errorMessage" class="text-danger mt-3">{{ errorMessage }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="editModal.hide()">Avbryt</button>
                        <button type="button" class="btn btn-primary" @click="submitEditModal">Spara</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Ta bort projekt</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click="deleteModal.hide()"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div>
                                <label for="nameInput" class="form-label">Skriv in '{{ project.name }}' för att kunna ta bort projektet</label>
                                <input type="text" class="form-control" id="nameInput" v-model="deleteName">
                                <div class="text-danger" v-show="states.deleteNameNotMatching">Det exakta namnet måste skrivas in</div>
                            </div>
                        </form>
                        <div v-show="errorMessage" class="text-danger mt-3">{{ errorMessage }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="deleteModal.hide()">Avbryt</button>
                        <button type="button" class="btn btn-danger" id="deleteButton" @click="submitDeleteModal">Ta bort projekt</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import projectsService from '../services/projectsService'
import tokenService from '../services/tokenService'
import helpers from '../helpers/helpers'
import { Modal } from 'bootstrap'

let editModal = ref(null)
let deleteModal = ref(null)
let isAdmin = ref(false)
const defaultContactMeText = 'Mätplatsen uppfyller inte kraven, vänligen kontakta mig.'

const projects = ref([])
const project = ref({
    id: null,
    name: '',
    description: '',
    contactMeCheckbox: false,
    contactMeText: defaultContactMeText,
    is_archived: false,
})

const deleteName = ref('')

const states = reactive({
    projectNameEmpty: false,
    projectNameTaken: false,
    deleteNameNotMatching: false,
    contaceMeTextEmpty: false,
})
const showArchived = ref(false)
const errorMessage = ref(null)

function showEditModal(projectId) {
    if (projectId) {
        const p = projects.value.find(p => p.id == projectId)
        project.value.id = p.id
        project.value.name = p.name
        project.value.description = p.description
        project.value.is_archived = p.is_archived
        project.value.contactMeCheckbox = p.properties?.contact_me_checkbox ?? false
        project.value.contactMeText = p.properties?.contact_me_text ?? defaultContactMeText
    } else {
        project.value.id = null
        project.value.name = ''
        project.value.description = ''
        project.value.is_archived = false
        project.value.contactMeCheckbox = false
        project.value.contactMeText = defaultContactMeText
    }

    errorMessage.value = null
    states.projectNameEmpty = false
    states.projectNameTaken = false
    states.contaceMeTextEmpty = false

    editModal.value.show()
}

async function submitEditModal() {
    let valid = true

    // Validate project
    states.projectNameEmpty = false
    states.projectNameTaken = false
    states.contaceMeTextEmpty = false

    if (project.value.name.trim().length === 0) {
        states.projectNameEmpty = true
        valid = false
    } else {
        const projectName = projects.value.find(p => p.name == project.value.name.trim())
        
        if (projectName && (!project.value.id || project.value.id != projectName.id)) {
            states.projectNameTaken = true
            valid = false
        }
    }

    if (project.value.contactMeCheckbox && project.value.contactMeText.trim().length === 0) {
        states.contaceMeTextEmpty = true
        valid = false
    }

    if (!valid) {
        return
    }

    try {
        if (project.value.id) {
            await projectsService.updateProject({ 
                id: project.value.id,
                name: project.value.name,
                description: project.value.description,
                properties: {
                    contact_me_checkbox: project.value.contactMeCheckbox,
                    contact_me_text: project.value.contactMeText,
                },
                is_archived: project.value.is_archived,
            })
        } else {
            await projectsService.createProject(project.value)
        }

        editModal.value.hide()
        reload()
    } catch (err) {
        console.log(err)
        errorMessage.value = helpers.getErrorMessage(err)
    }
}

function showDeleteModal(projectId) {
    const p = projects.value.find(p => p.id == projectId)
    deleteName.value = ''
    project.value = { ...p }
    states.deleteNameNotMatching = false
    errorMessage.value = null
    deleteModal.value.show()
}

async function submitDeleteModal() {
    if (deleteName.value == project.value.name) {
        states.deleteNameNotMatching = false
        try {
            await projectsService.deleteProject(project.value.id)

            deleteModal.value.hide()
            reload()
        } catch (err) {
            errorMessage.value = helpers.getErrorMessage(err)
        }
    } else {
        states.deleteNameNotMatching = true
    }
}

async function reload() {
    const dbProjects = (await projectsService.getProjects(showArchived.value))?.data
    if (showArchived.value) {
        projects.value = dbProjects
    } else {
        projects.value = dbProjects.filter(p => !p.is_archived)
    }
    projects.value = projects.value.sort((a, b) => helpers.compareStrings(a.name, b.name))
}

onMounted(async () => {
    editModal.value = new Modal(document.getElementById('editModal'))
    deleteModal.value = new Modal(document.getElementById('deleteModal'))
    const user = tokenService.getUser()
    isAdmin.value = user.level == 10
    reload()
})

onBeforeRouteLeave((to, from, next) => {
    //editModal.hide()
    //deleteModal.hide()
    next()
})
</script>