<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col">
                <h2>{{ project.name }}{{ project.is_archived ? ' (arkiverat)' : '' }}</h2>
            </div>
            <div class="col" v-if="user.level <= 10">
                <div class="float-top float-end">
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <button id="dashboardTabButton" class="btn btn-link nav-link" @click="goToTab('dashboard')">Översikt</button>
            </li>
            <li class="nav-item">
                <button id="jobsTabButton" class="btn btn-link nav-link active" @click="goToTab('jobs')">Jobb</button>
            </li>
            <li class="nav-item">
                <button id="timewindowsTabButton" class="btn btn-link nav-link" @click="goToTab('timewindows')">Tider</button>
            </li>
            <li class="nav-item">
                <button id="reportsTabButton" class="btn btn-link nav-link" @click="goToTab('reports')">Rapporter</button>
            </li>
        </ul>
        <div>
            <Dashboard v-if="tab === 'dashboard'" :project="project" :stats="stats" />
            <Jobs v-if="tab === 'jobs'" :project="project" :jobs="jobs" @reload="reload" />
            <TimeWindows v-if="tab === 'timewindows'" :project="project" @reload="reload" @show-bookings="onShowTimeWindowBookings" />
            <Reports v-if="tab === 'reports'" :project="project" />
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
// Serives
import tokenService from '@/services/tokenService'
import projectsService from '@/services/projectsService'
// Components
import Dashboard from '@/components/Dashboard.vue'
import Jobs from '@/components/Jobs.vue'
import TimeWindows from '@/components/TimeWindows.vue'
import Reports from '@/components/Reports.vue'

const route = useRoute()

let project = reactive({
    id: null,
    name: null,
    is_archived: null,
})
let stats = reactive({
    jobs_unbooked: null,
    jobs_passed: null,
    jobs_booked: null,
    jobs_ongoing: null,
    jobs_done: null,
    jobs_missed: null,
    stats_available: null,
    stats_missed: null,
    stats_canceled: null,
    stats_contact_updates: null,
    stats_contact_updates_admin: null,
    stats_rebookings: null,
    stats_rebookings_admin: null,
})
let jobs = reactive({
    array: [],
    searchString: '',
    statusFilter: 'all',
    count: 0,
    pages: 0,
    page: 1,
    size: 50,
    sortCol: 'time_window',
    sortDir: 'ASC',
    timeWindowId: '',
    timeWindows: [],
})

let tab = ref('jobs')
let user = tokenService.getUser()

function onShowTimeWindowBookings(args) {
    jobs.timeWindowId = args.timeWindowId
    jobs.page = 1
    jobs.searchString = ''
    jobs.statusFilter = 'all'
    jobs.sortCol = 'location_address'
    jobs.sortDir = 'ASC'
    jobs.size = 50
    goToTab('jobs')
    reload()
}

function goToTab(to) {
    let oldTab = document.getElementById(tab.value + 'TabButton')
    oldTab.classList.remove('active')
    let newTab = document.getElementById(to + 'TabButton')
    newTab.classList.add('active')
    tab.value = to
}

async function reload() {
    const projectId = route.params.id

    const projectInfo = (await projectsService.getProject(projectId)).data
    project.name = projectInfo.name
    project.id = projectInfo.id
    project.is_archived = projectInfo.is_archived

    const projectStats = (await projectsService.getProjectStats(projectId)).data
    for (const key in projectStats) {
        stats[key] = projectStats[key]
    }

    const filter = {
        page: jobs.page,
        size: jobs.size,
        search: jobs.searchString,
        status: jobs.statusFilter,
        timeWindowId: jobs.timeWindowId,
        column: jobs.sortCol,
        dir: jobs.sortDir
    }

    const jobsCount = (await projectsService.getJobsCount(projectId, filter)).data
    jobs.count = jobsCount.count
    jobs.pages = Math.ceil(jobsCount.count / jobs.size)

    projectsService.getJobs(projectId, filter).then(response => {
        jobs.array = response.data
    })
    .catch(error => {
        console.error(error)
    })

    const oneYearFromNow = new Date()
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

    let timeWindows = (await projectsService.getTimeWindows(projectId, new Date(), oneYearFromNow)).data
    timeWindows.sort((a, b) => a.date === b.date ? a.index - b.index : new Date(a.date) - new Date(b.date))
    jobs.timeWindows = timeWindows
}

reload()
</script>