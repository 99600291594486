<template>
    <div class="container mb-5">
        <div class="row border-start border-end border-bottom" style="background-color: white;">
            <div class="row mt-3 mb-3">
                <div class="col-3"></div>
                <div class="col-2">
                    <label for="yearSelect" class="form-label">År</label>
                    <select id="yearSelect" class="form-select" @change="reload" v-model="year">
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                    </select>
                </div>
                <div class="col-2">
                    <label for="weekSelect" class="form-label">Vecka</label>
                    <select id="weekSelect" class="form-select" @change="reload" v-model="week">
                        <option v-for="week in weeks" :key="week" :value="week">{{ week }}</option>
                    </select>
                </div>
                <div class="col-2">
                    <div style="margin-top: 2rem">
                        <button class="btn btn-outline-secondary" @click="onPreviousWeek" title="Föregående vecka">&lt;</button>
                        &nbsp;
                        <button class="btn btn-outline-secondary" @click="onNextWeek" title="Nästa vecka">&gt;</button>
                    </div>
                </div>
                <div class="col-3">
                    <button class="btn btn-secondary" @click="showImportTimeWindowsModal">Importera tider</button>
                </div>
            </div>
            <!-- Calendar -->
            <div style="text-align: center;">
                <div class="row mb-1">
                    <div class="col-1">
                    </div>
                    <div class="col-2">
                        mån {{ days[0].label }}
                    </div>
                    <div class="col-2">
                        tis {{ days[1].label }}
                    </div>
                    <div class="col-2">
                        ons {{ days[2].label }}
                    </div>
                    <div class="col-2">
                        tor {{ days[3].label }}
                    </div>
                    <div class="col-2">
                        fre {{ days[4].label }}
                    </div>
                </div>
                <div class="row" v-for="slot in 4">
                    <div class="col-1" style="height: 3rem; text-align:right">
                        <div style="top:50%; margin-top:-10px;">
                            {{ new String(hours[slot - 1]).padStart(2, '0') + ':00' }}
                        </div>
                    </div>
                    <div v-for="day in 5" :class="'col-2 window ' + getStyle(day - 1, slot - 1)" @click="showEditTimeWindowModal(day - 1, slot - 1)">
                        {{ (days[day - 1].windows[slot - 1].t > 0 || days[day - 1].windows[slot - 1].b > 0) ? days[day - 1].windows[slot - 1].b + ' / ' + days[day - 1].windows[slot - 1].t : '' }}
                    </div> 
                </div>
                <div class="row">
                    <div class="col-1" style="height: 3rem; text-align:right">
                        <div style="top:50%; margin-top:-15px">
                            {{ new String(hours[4]).padStart(2, '0') + ':00' }}
                        </div>
                    </div>
                    <div class="col-10" style="border-top: 1px solid #000;">
                        &nbsp;
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2"><div class="box nulled"></div>&nbsp;Ingen lucka inlagd</div>
                <div class="col-2"><div class="box empty"></div>&nbsp;Ingen bokning</div>
                <div class="col-2"><div class="box available"></div>&nbsp;Delvis bokad</div>
                <div class="col-2"><div class="box full"></div>&nbsp;Fullbokad</div>
                <div class="col-2"><div class="box overbooked"></div>&nbsp;Överbokad</div>
                <div class="col-2"><div class="box passed"></div>&nbsp;Datum passerad</div>
            </div>
        </div>
        <ImportTimeWindowsModal id="importTimeWindowsModal" :data="importTimeWindowsData" @close="importTimeWindowsModal.hide()" @reload="reload" />
        <EditTimeWindowModal id="editTimeWindowModal" :data="editTimeWindowData" @close="editTimeWindowModal.hide()" @reload="reload" 
            @show-bookings="editTimeWindowModal.hide(); $emit('show-bookings', { timeWindowId: editTimeWindowData.timeWindowId })"
        />
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { Modal } from 'bootstrap'
import dateTime from '@/helpers/dateTime'
import projectsService from '@/services/projectsService'
import ImportTimeWindowsModal from '@/components/ImportTimeWindowsModal.vue'
import EditTimeWindowModal from '@/components/EditTimeWindowModal.vue'

const emit = defineEmits([ 'reload', 'show-bookings' ])

const props = defineProps({
    project: Object
})

let importTimeWindowsModal = ref(null)
let editTimeWindowModal = ref(null)
let year = ref(new Date().getFullYear())
let week = ref(dateTime.getWeekNumber(new Date()))
let years = ref([])
let weeks = ref([])
const hours = ref([ 7, 10, 13, 16, 20 ])
const days = reactive({
    0: { label: '', date: null, windows: { 0: { id: null, t: 0, b: 0 }, 1: { id: null, t: 0, b: 0 }, 2: { id: null, t: 0, b: 0 }, 3: { id: null, t: 0, b: 0 }, 4: { id: null, t: 0, b: 0 } } },
    1: { label: '', date: null, windows: { 0: { id: null, t: 0, b: 0 }, 1: { id: null, t: 0, b: 0 }, 2: { id: null, t: 0, b: 0 }, 3: { id: null, t: 0, b: 0 }, 4: { id: null, t: 0, b: 0 } } },
    2: { label: '', date: null, windows: { 0: { id: null, t: 0, b: 0 }, 1: { id: null, t: 0, b: 0 }, 2: { id: null, t: 0, b: 0 }, 3: { id: null, t: 0, b: 0 }, 4: { id: null, t: 0, b: 0 } } },
    3: { label: '', date: null, windows: { 0: { id: null, t: 0, b: 0 }, 1: { id: null, t: 0, b: 0 }, 2: { id: null, t: 0, b: 0 }, 3: { id: null, t: 0, b: 0 }, 4: { id: null, t: 0, b: 0 } } },
    4: { label: '', date: null, windows: { 0: { id: null, t: 0, b: 0 }, 1: { id: null, t: 0, b: 0 }, 2: { id: null, t: 0, b: 0 }, 3: { id: null, t: 0, b: 0 }, 4: { id: null, t: 0, b: 0 } } },
})

const importTimeWindowsData = reactive({
    fileInputKey: 0,
    errorMessage: null,
    successMessage: null,
})

const editTimeWindowData = reactive({
    projectId: props.project.id,
    timeWindowId: null,
    date: null,
    index: null,
    total: null,
    booked: null,
    ongoing: null,
    done: null,
    missed: null,
    errorMessage: null,
})

years = Array.from({ length: 10 }, (_, i) => year.value - 5 + i).map(String)

reload()

function onPreviousWeek() {
    if (week.value > 1) {
        week.value--
    } else {
        year.value--
        week.value = dateTime.getWeeksInYear(year.value)
    }
    reload()
}

function onNextWeek() {
    if (week.value < weeks.length) {
        week.value++
    } else {
        year.value++
        week.value = 1
    }
    reload()
}

function getStyle(dayIndex, slotIndex) {
    const now = new Date()
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    if (days[dayIndex].date < midnight) {
        return 'passed'
    }
    const window = days[dayIndex].windows[slotIndex]
    if (window.b > window.t) {
        return 'overbooked'
    } else if (window.t === 0) {
        return 'nulled'
    } else if (window.b === window.t) {
        return 'full'
    } else if (window.b === 0) {
        return 'empty'
    } else {
        return 'available'
    }
}

async function reload() {
    const weeksCount = dateTime.getWeeksInYear(year.value)
    weeks = Array.from({ length: weeksCount }, (_, i) => i + 1).map(String)
    if (week.value > weeksCount) {
        week.value = weeksCount
    }
    const monday = dateTime.getMonday(year.value, week.value)
    const nextMonday = dateTime.add(monday, 'day', 7)

    const windows = (await projectsService.getTimeWindows(props.project.id, monday, nextMonday)).data

    let date = monday
    for (let day = 0; day < 5; day++) {
        days[day].date = date
        days[day].label = date.getDate() + ' ' + dateTime.getMonthString(date.getMonth())
        for (let index = 0; index < 4; index++) {
            days[day].windows[index].id = null
            days[day].windows[index].t = 0
            days[day].windows[index].b = 0
        }
        date = dateTime.add(date, 'day', 1)
    }

    for (const window of windows) {
        const day = new Date(window.date).getDay() - 1
        const index = new Number(window.index)
        days[day].windows[index].t = window.total_nbr_spots
        days[day].windows[index].b = window.nbr_booked_spots
        days[day].windows[index].id = window.id
    }

    emit('reload')
}

function showImportTimeWindowsModal() {
    importTimeWindowsData.fileInputKey++
    importTimeWindowsData.errorMessage = null
    importTimeWindowsData.successMessage = null
    importTimeWindowsModal.show()
}

function showEditTimeWindowModal(dayIndex, windowIndex) {
    const id = days[dayIndex].windows[windowIndex].id

    editTimeWindowData.booked = 0
    editTimeWindowData.ongoing = 0
    editTimeWindowData.done = 0
    editTimeWindowData.missed = 0
    editTimeWindowData.errorMessage = null

    if (id === null) {
        editTimeWindowData.total = 0
        editTimeWindowData.date = days[dayIndex].date
        editTimeWindowData.index = windowIndex
        editTimeWindowData.timeWindowId = null
        editTimeWindowModal.show()
        return
    }

    projectsService.getTimeWindowInfo(id).then(response => {
        editTimeWindowData.timeWindowId = id
        editTimeWindowData.total = days[dayIndex].windows[windowIndex].t
        for (const row of response.data) {
            switch (row.status) {
                case 1:
                    editTimeWindowData.booked = new Number(row.status_count)
                    break
                case 2:
                    editTimeWindowData.ongoing = new Number(row.status_count)
                    break
                case 3:
                    editTimeWindowData.done = new Number(row.status_count)
                    break
                case 4:
                    editTimeWindowData.missed = new Number(row.status_count)
                    break
            }
        }
    }).catch(error => {
        console.error(error.response.message)
    })
    editTimeWindowData.errorMessage = null
    editTimeWindowModal.show()
}

onMounted(() => {
    importTimeWindowsModal = new Modal(document.getElementById('importTimeWindowsModal'))
    editTimeWindowModal = new Modal(document.getElementById('editTimeWindowModal'))
})

onBeforeRouteLeave((to, from) => {
    importTimeWindowsModal.hide()
    editTimeWindowModal.hide()
})

</script>

<style scoped>
.full {
    background-color: #ffe25f;
    color: #000;
}
.nulled {
    background-color: #e9e9e9;
    color: #000;
}
.empty {
    background-color: #24deff;
}
.passed {
    background-color: #b1b1b1;
    color: #000;
}
.overbooked {
    background-color: #ff4949;
    color: #000;
}
.window {
    height: 4rem;
    padding: 1rem;
    border-top: 1px solid #000;
    border-left: 10px solid #fff;
}
.available {
    background-color: #7eff7e;
    color: #000;
}
.window:hover {
    filter: brightness(0.8);
}
.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid black;
  clear: both;
}
</style>