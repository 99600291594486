<template>
    <div class="modal" id="importModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Importera arbeten</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-info">
                        Importera arbeten från Excel-fil (.xlsx).<br/>
                        Följande kolumner måste finnas: Anlnr, Mätarnummer, Anläggningsadress, Nr, Postnummer anläggning, Anläggningsort, Namn, Postadress, Postnummer, Ort, Mejladress, Telefonnummer
                    </div>
                    <form>
                        <div class="mb-3">
                            <label for="fileInput" class="form-label">Välj fil</label>
                            <input ref="fileInput" class="form-control" type="file" id="fileInput" :key="data.fileInputKey"/>
                        </div>
                        <div class="mb-3">
                            <label for="jobDescriptionInput" class="form-label">Beskrivning (synlig för kund)</label>
                            <input id="jobDescriptionInput" class="form-control" v-model="jobDescription" type="text"/>
                        </div>
                    </form>
                    <div v-show="data.errorMessage" class="text-danger">
                        {{ data.errorMessage }}
                    </div>
                    <div v-show="data.successMessage" class="text-success">
                        {{ data.successMessage }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">{{ data.successMessage ? 'Stäng' : 'Avbryt' }}</button>
                    <button type="button" class="btn btn-primary" v-show="!data.successMessage" @click.once="onSubmit" :key="data.fileInputKey">Importera</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import readXlsxFile from 'read-excel-file'
import projectsService from '@/services/projectsService'

const props = defineProps({
    data: Object
})

const emit = defineEmits([ 'close', 'reload' ])

const jobDescription = ref('')
const fileInput = ref(null)
const route = useRoute()

async function onSubmit() {
    props.data.errorMessage = null
    props.data.successMessage = null

    const file = fileInput.value.files[0]

    if (!file) {
        props.data.errorMessage = 'Ingen fil vald'
        props.data.fileInputKey++
        return
    }

    const reader = new FileReader()
    let content = null

    reader.onload = res => {
        content = res.target.result
    }
    reader.onerror = () => {
        props.data.errorMessage = 'Fel vid inläsning av filen'
    }
    reader.onloadend = async () => {
        try {
            const rows = await readXlsxFile(content)

            if (rows.length === 0) {
                props.data.errorMessage = 'Inga rader hittades i filen'
                props.data.fileInputKey++
                return
            }

            const headers = {}
            for (let i = 0; i < rows[0].length; i++) {
                headers[rows[0][i]] = i
            }

            if (headers['Anlnr'] == null ||
                headers['Mätarnummer'] == null ||
                headers['Anläggningsadress'] == null ||
                headers['Nr'] == null ||
                headers['Postnummer anläggning'] == null ||
                headers['Anläggningsort'] == null ||
                headers['Namn'] == null ||
                headers['Postadress'] == null ||
                headers['Postnummer'] == null ||
                headers['Ort'] == null ||
                headers['Mejladress'] == null ||
                headers['Telefonnummer'] == null
            ) {
                props.data.errorMessage = 'Följande kolumner måste finnas: Anlnr, Mätarnummer, Anläggningsadress, Nr, Postnummer anläggning, Anläggningsort, Namn, Postadress, Postnummer, Ort, Mejladress, Telefonnummer'
                props.data.fileInputKey++
                return
            }

            const jobs = []

            let firstRow = true
            for (let row of rows) {
                if (firstRow) {
                    firstRow = false
                    continue
                }
                const locationAddress = getValueAsString(row[headers['Anläggningsadress']])
                const locationNr = getValueAsString(row[headers['Nr']])

                const job = {
                    description: jobDescription.value,
                    location_number: getValueAsString(row[headers['Anlnr']]),
                    location_address: locationAddress + (locationNr.length > 0 ? (' ' + locationNr) : ''),
                    location_city: getValueAsString(row[headers['Anläggningsort']]),
                    extra_info: {
                        meterNumber: getValueAsString(row[headers['Mätarnummer']]),
                        locationZip: getValueAsString(row[headers['Postnummer anläggning']]),
                    },
                    user_info: {
                        created: new Date(),
                        name: getValueAsString(row[headers['Namn']]),
                        address: getValueAsString(row[headers['Postadress']]),
                        zip: getValueAsString(row[headers['Postnummer']]),
                        city: getValueAsString(row[headers['Ort']]),
                        email: getValueAsString(row[headers['Mejladress']]),
                        phone: getValueAsString(row[headers['Telefonnummer']])
                    }
                }

                jobs.push(job)
            }

            if (jobs.length === 0) {
                props.data.errorMessage = 'Inga arbeten att importera, filen är tom förutom rubrikraden.'
                props.data.fileInputKey++
                return
            }

            const importResult = (await projectsService.createJobs(route.params.id, jobs)).data

            props.data.successMessage = 'Importerade ' + importResult.count + ' arbeten'

            if (importResult.count > 0) {
                emit('reload')
            }
        } catch (error) {
            props.data.fileInputKey++
            if (error.data) {
                props.data.errorMessage = 'Serven svarar: ' + error.data.status + ' ' + error.data.statusText
            } else {
                console.log(error)
                props.data.errorMessage = 'Annat fel'
            }
        }
    }
    reader.readAsArrayBuffer(file)
}

function getValueAsString(value) {
    if (value == null) {
        return ''
    }
    return value.toString()
}
</script>