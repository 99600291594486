<template>
    <div class="container mb-5 border-start border-end border-bottom">
        <div class="row" style="background-color: white;">
            <div class="mt-3">
                <label for="reportSelect" class="form-label">Välj rapport</label>
                <select id="reportSelect" class="form-select" v-model="reportType">
                    <option value="jobs">Uppdaterad kontaktlista</option>
                    <option value="jobs_changed_only">Uppdaterad kontaktlista (endast ändrade)</option>
                    <option value="work_list">Arbetsorderlista</option>
                    <option value="booked_jobs">Aviseringslista</option>
                    <option value="failed_runs">Bomkörningar</option>
                    <option value="cancellations">Avbokningar</option>
                    <option value="job_completed">Utförda jobb</option>
                    <option value="rebookings">Ombokningar</option>
                </select>
            </div>
            <div class="mt-3" v-show="reportType === 'work_list' || reportType === 'booked_jobs'">
                <div class="row">
                    <div class="col-3">
                        <label for="dateInput" class="form-label">Från och med</label>
                        <input id="dateInput" type="date" class="form-control" v-model="startDate" />
                    </div>
                    <div class="col-3">
                        <label for="dateInput" class="form-label">Till och med</label>
                        <input id="dateInput" type="date" class="form-control" v-model="stopDate" />
                    </div>
                </div>
                <div v-if="errors.dates">
                    <small class="text-danger">{{ errors.dates }}</small>
                </div>
            </div>
            <div class="mt-3 mb-3">
                <button type="button" class="btn btn-primary" @click="onSubmit" :disabled="!reportType">Ladda
                    ner</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import projectsService from '@/services/projectsService'
import writeXlsxFile from 'write-excel-file'

const props = defineProps({
    project: Object,
})

const reportType = ref(null)
const startDate = ref(new Date().toISOString().substring(0, 10))
const stopDate = ref(new Date().toISOString().substring(0, 10))
const errors = reactive({
    dates: null,
})

function indexToTimeWindow(index) {
    switch (index) {
        case 0:
            return '07:00-10:00'
        case 1:
            return '10:00-13:00'
        case 2:
            return '13:00-16:00'
        case 3:
            return '16:00-20:00'
    }
}

function formatBookingNumber(number) {
    return number.substring(0, 3) + '-' + number.substring(3, 6) + '-' + number.substring(6, 9) + '-' + number.substring(9)
}

async function onSubmit() {
    const contactsListScheme = [
        {
            column: 'Anlnr',
            type: String,
            width: 10,
            value: job => job.extra_info ? job.location_number.toString() : ''
        },
        {
            column: 'Mätarnummer',
            type: String,
            width: 15,
            value: job => job.extra_info ? job.extra_info.meterNumber.toString() : ''
        },
        {
            column: 'Anläggningsadress',
            type: String,
            width: 26,
            value: job => job.location_address
        },
        {
            column: 'Postnummer anläggning',
            type: String,
            width: 26,
            value: job => job.extra_info ? job.extra_info.locationZip?.toString() : ''
        },
        {
            column: 'Anläggningsort',
            type: String,
            width: 26,
            value: job => job.location_city
        },
        {
            column: 'Namn',
            type: String,
            width: 37,
            value: job => job.user_info.name
        },
        {
            column: 'Postadress',
            type: String,
            width: 42,
            value: job => job.user_info.address
        },
        {
            column: 'Postnummer',
            type: String,
            width: 18,
            value: job => job.user_info.zip.toString()
        },
        {
            column: 'Ort',
            type: String,
            width: 18,
            value: job => job.user_info.city
        },
        {
            column: 'Mejladress',
            type: String,
            width: 50,
            value: job => job.user_info.email
        },
        {
            column: 'Telefonnummer',
            type: String,
            width: 25,
            value: job => job.user_info.phone.toString()
        }
    ]

    const workScheme = [
        {
            column: 'Datum',
            type: String,
            width: 12,
            borderStyle: 'thin',
            borderColor: '#000000',
            height: 20,
            verticalAlign: 'middle',
            value: job => job.date
        },
        {
            column: 'Tid',
            type: String,
            width: 12,
            borderStyle: 'thin',
            borderColor: '#000000',
            value: job => job.time
        },
        {
            column: 'Anläggningsadress',
            type: String,
            width: 25,
            borderStyle: 'thin',
            borderColor: '#000000',
            value: job => job.location_address
        },
        {
            column: 'Anläggningsort',
            type: String,
            width: 15,
            borderStyle: 'thin',
            borderColor: '#000000',
            value: job => job.location_city
        },
        {
            column: 'Telefon',
            type: String,
            width: 15,
            borderStyle: 'thin',
            borderColor: '#000000',
            value: job => job.user_info.phone.toString()
        },
        {
            column: 'Mätarnr.',
            type: String,
            width: 10,
            borderStyle: 'thin',
            borderColor: '#000000',
            value: job => job.extra_info ? job.extra_info.meterNumber.toString() : ''
        },
        {
            column: 'Nytt mätarnr.',
            type: String,
            width: 17,
            borderStyle: 'thin',
            borderColor: '#000000',
            value: () => ''
        },
        {
            column: 'Kommentar',
            type: String,
            width: 40,
            borderStyle: 'thin',
            borderColor: '#000000',
            value: () => ''
        },
    ]

    const bookedJobsScheme = [
        {
            column: 'Bokningsnr.',
            type: String,
            width: 20,
            value: job => formatBookingNumber(job.number)
        },
        {
            column: 'Anläggningsadress',
            type: String,
            width: 24,
            value: job => job.location_address
        },
        {
            column: 'Postnummer anläggning',
            type: String,
            width: 12,
            value: job => job.extra_info ? job.extra_info.locationZip?.toString() : ''
        },
        {
            column: 'Anläggningsort',
            type: String,
            width: 12,
            value: job => job.location_city
        },
        {
            column: 'Datum',
            type: String,
            width: 12,
            verticalAlign: 'middle',
            value: job => job.date
        },
        {
            column: 'Tid',
            type: String,
            width: 12,
            value: job => job.time
        },
        {
            column: 'Direktlänk',
            type: String,
            width: 50,
            value: job => 'https://ombokning.oneviewer.se?n=' + job.number
        },
        {
            column: 'Namn',
            type: String,
            width: 37,
            value: job => job.user_info.name
        },
        {
            column: 'Adress',
            type: String,
            width: 42,
            value: job => job.user_info.address
        },
        {
            column: 'Postnummer',
            type: String,
            width: 18,
            value: job => job.user_info.zip.toString()
        },
        {
            column: 'Postort',
            type: String,
            width: 18,
            value: job => job.user_info.city
        },
        {
            column: 'E-post',
            type: String,
            width: 50,
            value: job => job.user_info.email
        },
        {
            column: 'Telefon',
            type: String,
            width: 25,
            value: job => job.user_info.phone.toString()
        },
    ]

    const failedRunsScheme = [
        {
            column: 'Anläggningsadress',
            type: String,
            width: 25,
            value: job => job.location_address
        },
        {
            column: 'Anläggningsort',
            type: String,
            width: 15,
            value: job => job.location_city
        },
        {
            column: 'Datum',
            type: String,
            width: 12,
            value: job => new Date(job.info.date).toLocaleDateString('sv-SE')
        },
        {
            column: 'Tid',
            type: String,
            width: 12,
            value: job => indexToTimeWindow(job.info.index)
        },
        {
            column: 'Mätarnr.',
            type: String,
            width: 10,
            value: job => job.extra_info ? job.extra_info.meterNumber.toString() : ''
        },
        {
            column: 'Kommentar',
            type: String,
            width: 60,
            value: job => job.info.comment
        },
    ]

    const canelledScheme = [
        {
            column: 'Anläggningsadress',
            type: String,
            width: 25,
            value: job => job.location_address
        },
        {
            column: 'Anläggningsort',
            type: String,
            width: 15,
            value: job => job.location_city
        },
        {
            column: 'Datum',
            type: String,
            width: 12,
            value: job => new Date(job.info.date).toLocaleDateString('sv-SE')
        },
        {
            column: 'Tid',
            type: String,
            width: 12,
            value: job => indexToTimeWindow(job.info.index)
        },
        {
            column: 'Avbokad den',
            type: String,
            width: 16,
            value: job => new Date(job.timestamp).toLocaleString('sv-SE', { dateStyle: 'short', timeStyle: 'short' })
        },
        {
            column: 'Mätarnr.',
            type: String,
            width: 10,
            value: job => job.extra_info ? job.extra_info.meterNumber.toString() : ''
        },
        {
            column: 'Kommentar',
            type: String,
            width: 60,
            value: job => job.info.comment
        },
    ]

    const completedJobsScheme = [
        {
            column: 'Anläggningsadress',
            type: String,
            width: 25,
            value: job => job.location_address
        },
        {
            column: 'Anläggningsort',
            type: String,
            width: 15,
            value: job => job.location_city
        },
        {
            column: 'Datum',
            type: String,
            width: 12,
            value: job => job.date
        },
        {
            column: 'Tid',
            type: String,
            width: 12,
            value: job => indexToTimeWindow(job.index)
        },
        {
            column: 'Mätarnr.',
            type: String,
            width: 10,
            value: job => job.extra_info ? job.extra_info.meterNumber.toString() : ''
        },
        {
            column: 'Kommentar',
            type: String,
            width: 60,
            value: job => job.info.comment
        },
    ]

    const rebookingsJobsScheme = [
        {
            column: 'Anläggningsadress',
            type: String,
            width: 25,
            value: job => job.location_address
        },
        {
            column: 'Anläggningsort',
            type: String,
            width: 15,
            value: job => job.location_city
        },
        {
            column: 'Från datum',
            type: String,
            width: 12,
            value: job => new Date(job.info.oldDate).toLocaleDateString('sv-SE')
        },
        {
            column: 'Från tid',
            type: String,
            width: 12,
            value: job => indexToTimeWindow(job.info.oldIndex)
        },
        {
            column: 'Till datum',
            type: String,
            width: 12,
            value: job => new Date(job.info.newDate).toLocaleDateString('sv-SE')
        },
        {
            column: 'Till tid',
            type: String,
            width: 12,
            value: job => indexToTimeWindow(job.info.newIndex)
        },
        {
            column: 'Ombokad av',
            type: String,
            width: 12,
            value: job => job.log_name === 'rebook' ? 'Kund' : 'Admin.'
        },
        {
            column: 'Ombokad den',
            type: String,
            width: 16,
            value: job => new Date(job.timestamp).toLocaleString('sv-SE', { dateStyle: 'short', timeStyle: 'short' })
        },
        {
            column: 'Mätarnr.',
            type: String,
            width: 10,
            value: job => job.extra_info ? job.extra_info.meterNumber.toString() : ''
        },
        {
            column: 'Kommentar',
            type: String,
            width: 60,
            value: job => job.info.comment
        },
    ]

    const defaultOptions = {
        fontFamily: 'Calibri',
        fontSize: 11,
        dateFormat: 'yyyy-mm-dd',
        headerStyle: {
            backgroundColor: '#c0c0c0',
            align: 'center',
            fontSize: 11,
            verticalAlign: 'middle',
        },
        schema: contactsListScheme,
    }

    errors.dates = null
    if (reportType.value === null) {
        return
    }

    let schema = null
    let name = null
    let params = {}
    let date = new Date().toLocaleDateString('sv-SE')
    switch (reportType.value) {
        case 'jobs':
        case 'jobs_changed_only':
            schema = contactsListScheme
            name = reportType.value === 'jobs' ? 'Kontaktlista' : 'Uppdaterad kontaktlista'
            if (reportType.value === 'jobs_changed_only') {
                schema.push({
                    column: 'Ändrad av',
                    type: String,
                    width: 12,
                    value: job => job.log_name.endsWith('_admin') ? 'Kundtjänst' : 'Kund'
                })
            }
            break
        case 'work_list':
        case 'booked_jobs':
            if (new Date(startDate.value + 'T00:00').getTime() > new Date(stopDate.value + 'T00:00').getTime()) {
                errors.dates = 'Startdatum måste vara tidigare än slutdatum'
                return
            }
            schema = reportType.value === 'work_list' ? workScheme : bookedJobsScheme
            name = reportType.value === 'work_list' ? 'Arbetsorderlista' : 'Aviseringslista'
            params.start = startDate.value
            params.stop = stopDate.value
            date = params.start + '-' + params.stop
            break
        case 'failed_runs':
            schema = failedRunsScheme
            name = 'Bomkörningar'
            break
        case 'cancellations':
            schema = canelledScheme
            name = 'Avbokningar'
            break
        case 'job_completed':
            schema = completedJobsScheme
            name = 'Utförda jobb'
            break
        case 'rebookings':
            schema = rebookingsJobsScheme
            name = 'Ombokningar'
            break
    }

    const options = defaultOptions
    if (schema && name) {
        options.fileName = name + ' ' + props.project.name + ' ' + date + '.xlsx'
        options.schema = schema
    }

    const data = (await projectsService.getReport(props.project.id, reportType.value, params)).data

    console.log(data)

    await writeXlsxFile(data, options)
}

</script>