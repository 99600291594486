<template>
    <div class="modal" id="editModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Ändra status</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="statusSelect" class="form-label">Ny status</label>
                            <select id="statusSelect" class="form-select" v-model="job.status">
                                <option value="1">Bokat</option>
                                <option value="2">Påbörjat</option>
                                <option value="3">Utfört</option>
                                <option value="4">Bomkörning</option>
                                <option value="5">Kontakta</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="commentInput" class="form-label">Kommentar</label>
                            <input id="commentInput" class="form-control" v-model="job.comment" type="text" :disabled="job.skipComment"/>
                        </div>
                        <div class="mb-3" v-show="job.status != job.originalStatus">
                            <input id="skipCommentCheckbox" class="form-check-input" type="checkbox" v-model="job.skipComment"/>
                            <label for="skipCommentCheckbox" class="form-check-label">&nbsp;Ange ingen kommentar</label>
                        </div>
                    </form>
                    <div v-show="job.errorMessage" class="text-danger">
                        {{ job.errorMessage }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Avbryt</button>
                    <button type="button" class="btn btn-primary" @click="onSubmit">Spara</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import projectsService from '@/services/projectsService'

const emit = defineEmits([ 'close', 'reload' ])

const props = defineProps({
    job: Object,
})

async function onSubmit() {
    if (props.job.status == props.job.originalStatus && props.job.skipComment) {
        emit('close')
        return
    }

    if (props.job.status != props.job.originalStatus && !props.job.skipComment && !props.job.comment) {
        props.job.errorMessage = 'Skriv kommentar eller bocka i "Ange ingen kommentar"'
        return
    }

    const response = await projectsService.updateJobStatus(props.job.id, props.job.status, props.job.comment)

    if (response.status === 200 || response.status === 201) {
        emit('reload')
        emit('close')
    } else {
        props.data.errorMessage = response.data.message
    }

}
</script>