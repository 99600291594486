<template>
    <div class="modal" id="editModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ data.unbooked ? 'Boka' : 'Boka om' }}</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-check mb-3" v-show="!data.unbooked">
                            <input class="form-check-input" type="checkbox" id="cancelCheckBox" v-model="data.cancelBooking">
                            <label class="form-check-label" for="cancelCheckBox">Avboka tid</label>
                        </div>
                        <div class="mb-3">
                            <label for="windowSelect" class="form-label">Välj tidslucka</label>
                            <select id="windowSelect" class="form-select" v-model="data.timeWindowId" :disabled="data.cancelBooking">
                                <option v-for="window in props.windows" :value="window.id">{{ new Date(window.date).toLocaleDateString('sv') }} {{ hours[window.index] }} ({{ window.nbr_booked_spots }} / {{ window.total_nbr_spots }})</option>
                            </select>
                        </div>
                        <div class="form-check mb-3" v-show="!data.unbooked && data.status != 1 && data.timeWindowId != data.originalTimeWindowId && !data.cancelBooking">
                            <input class="form-check-input" type="checkbox" id="statusCheckBox" v-model="data.resetStatus">
                            <label class="form-check-label" for="statusCheckBox">Ändra status till 'Bokat'</label>
                        </div>
                        <div v-show="data.cancelBooking">
                            <label for="commentInput" class="form-label">Kommentar</label>
                            <textarea id="commentInput" class="form-control" v-model="data.comment"></textarea>
                        </div>
                    </form>
                    <div v-show="data.errorMessage" class="text-danger">
                        {{ data.errorMessage }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Avbryt</button>
                    <button type="button" class="btn btn-primary" @click="onSubmit">Spara</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import projectsService from '@/services/projectsService'

const emit = defineEmits([ 'close', 'reload' ])
const hours = ref([ '07-10', '10-13', '13-16', '16-20' ])

const props = defineProps({
    data: Object,
    windows: Array,
})

async function onSubmit() {
    if (props.data.cancelBooking && !props.data.comment) {
        props.data.errorMessage = 'Skriv en kommentar'
        return
    }

    const response = await projectsService.updateJobTimeWindow(props.data.jobId, props.data.cancelBooking ? null : props.data.timeWindowId, props.data.comment, props.data.resetStatus)

    if (response.status === 200 || response.status === 201) {
        emit('reload')
        emit('close')
    } else {
        props.data.errorMessage = response.data.message
    }

}
</script>