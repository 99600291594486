<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
                <div class="navbar-brand ms-3">
                    OMBOKNING ADMIN
                    <!-- <img style="position: relative; bottom: 7px; max-height: 100px" src="/matarkontroll.png"/> -->
                </div>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav">
                        <router-link class="me-3" v-for="item in navbar.items" :key="item.path" :to="item.path" >{{ item.title }}</router-link>
                    </div>
                </div>
                <div class="nav-item dropdown d-flex me-3">
                    <a class="nav-link dropdown-toggle p-2" href="#" role="button" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false" v-show="navbar.isLoggedIn">
                        <i class="bi bi-person-fill" style="font-size: 30px" v-show="!navbar.displayName"></i>
                        {{ navbar.displayName }}
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end" style="margin: 0" aria-labelledby="userDropdown">
                        <li><a class="dropdown-item" href="#" @click="onLogout">Logga ut</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <router-view/>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import eventBus from './helpers/eventBus'
import tokenService from './services/tokenService'

const router = useRouter()

const allNavItems = [{
    path: '/',
    title: 'Projekt',
    maxLevel: 10
}, {
    path: '/users',
    title: 'Användare',
    maxLevel: 10
}]

const navbar = reactive({
    isLoggedIn: false,
    displayName: '',
    items: [],
})

function onLogout() {
    navbar.isLoggedIn = false
    tokenService.removeUser()
    updateNavbar()
    router.push('/login').catch(err => {
        console.log(err)
    })
}

function updateNavbar() {
    let user = tokenService.getUser()
    navbar.isLoggedIn = user != null
    navbar.displayName = user?.displayname
    navbar.items = []
    if (!navbar.isLoggedIn) {
        return
    }

    for (const item of allNavItems) {
        if (item.maxLevel && item.maxLevel >= user?.level ||
            item.minLevel && item.minLevel <= user?.level
        ) {
            let copy = { ...item }
            if (copy.path.indexOf(':siteId') >= 0) {
                copy.path = copy.path.replace(':siteId', user.siteId.toString())
            }

            navbar.items.push(copy)
        }
    }
}

onMounted(() => {
    eventBus.on('updateNavbar', updateNavbar)
    updateNavbar()
})

onBeforeUnmount(() => {
    eventBus.off('updateNavbar', updateNavbar)
})
</script>

<style>
.app {
    background-color: #f8f9fa;
}
.tab-panel {
    display: inline-block;
    background-color: #fff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.walkby-bg {
    background-color: #99ceff;
}
.bg-magenta {
    background-color: #ff00ff;
}
</style>