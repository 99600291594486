class TokenService {
    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem('user'))
        return user?.refreshToken
    }

    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem('user'))
        return user?.accessToken
    }

    updateLocalAccessToken(token, expires) {
        const user = JSON.parse(localStorage.getItem('user'))
        user.accessToken = token
        user.expires = expires
        localStorage.setItem('user', JSON.stringify(user))
    }

    getUser() {
        return JSON.parse(localStorage.getItem('user'))
    }

    getUserIsAdmin() {
        const user = this.getUser()
        return user.level == 10
    }

    setUser(user) {
        localStorage.setItem('user', JSON.stringify(user))
    }

    removeUser() {
        localStorage.removeItem('user')
    }

    tokenExpires() {
        const user = JSON.parse(localStorage.getItem('user'))

        // Check if token has expired or expires within 10 s
        return user?.expires && user.expires < new Date().getTime() - 10000
    }
}

export default new TokenService()
