<template>
    <div class="container">
        <h2>Logga in</h2>
        <form ref="form">
            <div class="mb-3 mt-3">
                <label for="username" class="form-label">Användarnamn</label>
                <input type="text" class="form-control" v-model="username" id="username" name="username" required />
                <div class="invalid-feedback">Vänligen ange användarnamn</div>
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">Lösenord</label>
                <input type="password" class="form-control" v-model="password" id="password" name="password" required />
                <div class="invalid-feedback">Vänligen ange lösenord</div>
            </div>
            <button class="btn btn-primary" @click.prevent="handleSubmit">Logga in</button>
        </form>
        <div v-if="error" class="mt-3 text-danger">{{error}}</div>
    </div>
</template>

<script>
import authService from '../services/authService'
import eventBus from '@/helpers/eventBus'
import { getIntendedRoute, clearIntendedRoute } from '@/router/index'

export default {
   data () {
        return {
            username: null,
            password: null,
            error: null,
            url: null
        }
    },
    methods: {
        keypress(e) {
            if (e.keyCode == 13) {
                this.handleSubmit(e)
            }
        },
        handleSubmit(e) {
            let valid = true
            if (!this.username) {
                valid = false
            }
            if (!this.password) {
                valid = false
            }

            if (valid) {
                authService.login({ username: this.username, password: this.password }).then(user => {
                    this.error = null 

                    const intendedRoute = getIntendedRoute()

                    if (intendedRoute) {
                        clearIntendedRoute()
                        this.$router.push(intendedRoute)
                    } else {
                        this.$router.push('/')
                    }
                    
                    eventBus.emit('updateNavbar')
                }).catch(error2 => {

                    if (error2.code === 'ERR_NETWORK') {
                        this.$router.push('/network_error')
                        return
                    }

                    if (error2.response.status === 401) {
                        this.error = 'Fel användarnamn eller lösenord'
                    } else {
                        this.error = 'Servern inte tillgänglig, kan vara internetanslutning som saknas.'
                    }
                })
            }
        }
    },
    mounted() {
        this.url = this.$route.params.url
    }
}
</script>