class Helpers {
    compareStrings(a, b) {
        return a.localeCompare(b, 'sv-SE')
    }

    getErrorMessage(err) {
        if (err.message) {
            this.errorMessage = err.message
        } else if (err.code) {
            this.errorMessage = err.code
        } else {
            this.errorMessage = 'Okänt fel'
        }
    }

    getDateTimeString(date) {
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const hour = date.getHours().toString().padStart(2, '0')
        const minute = date.getMinutes().toString().padStart(2, '0')
        return `${year}-${month}-${day} ${hour}:${minute}`
    }

    isLeapYear(year) {
        return ((year % 4) === 0 && (year % 100) !== 0) || year % 400 === 0
    }

    daysOfMonth(year, month) {
        const days = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]
        if (month == 2 && this.isLeapYear(year)) {
            return 29
        }
        return days[month - 1]
    }

    parseDateTimeString(str) {
        let parts = [ -1, -1, -1, -1, -1, -1 ]
        let part = 0

        for (let i = 0; i < str.length; i++) {
            const c = str.charAt(i)
            if (c >= '0' && c <= '9') {
                if (parts[part] === -1) {
                    parts[part] = 0
                } else {
                    parts[part] *= 10
                }
                parts[part] += Number.parseInt(c)
            } else {
                part++
                if (part >= parts.length) {
                    break
                }
            }
        }

        if (parts[0] < 1970 || parts[0] > 9999) {
            return null
        }

        if (parts[1] === -1) {
            parts[1] = 1
        } else if (parts[1] < 0 || parts[1] > 12) {
            return null
        }

        if (parts[2] === -1) {
            parts[2] = 1
        } else if (parts[2] < 1 || parts[2] > this.daysOfMonth(parts[0], parts[1])) {
            return null
        }

        if (parts[3] === -1) {
            parts[3] = 0
        } else if (parts[3] > 23) {
            return null            
        }

        if (parts[4] === -1) {
            parts[4] = 0
        } else if (parts[4] > 59) {
            return null
        }

        return new Date(`${parts[0]}-${parts[1]}-${parts[2]} ${parts[3]}:${parts[4]}`)
    }

    validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    }    
}

export default new Helpers()