<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h2>Användare</h2>
            </div>
            <div class="col">
                <div class="float-top float-end">
                    <button class="btn btn-primary ms-3" @click="showEditModal(null)">Lägg till användare</button>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div style="background-color: white; border: 1px solid #ccc;">
                <table class="table table-bordered mt-3">
                    <thead>
                        <tr>
                            <th>Namn</th>
                            <th>e-postadress</th>
                            <th>Låst</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in state.users" :key="user.id">
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td>
                                <i class="bi bi-lock-fill text-danger" v-show="user.is_locked"></i>
                            </td>
                            <td class="text-end">
                                <button class="btn btn-link btn-sm" @click="showEditModal(user.id)">Redigera</button>
                                <button class="btn btn-link btn-sm" @click="deleteUser(user.id)" :disabled="user.id == state.loggedInUser?.id">
                                    Ta bort
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="modal modal-lg" id="editModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ state.edit.id ? 'Redigera användare' : 'Lägg till användare' }}</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click="editModal.hide()"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="row">
                                <div class="col-6">
                                    <div class="mt-3">
                                        <label for="editUsername" class="form-label">Användarnamn</label>
                                        <input id="editUsername" type="text" class="form-control" v-model="state.edit.username"  />
                                        <div class="text-danger" v-show="state.errors.username">{{ state.errors.username }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 row">
                                <div class="col-6">
                                    <label for="editPassword" class="form-label">Lösenord</label>
                                    <input id="editPassword" type="password" class="form-control" v-model="state.edit.password" />
                                    <div class="text-danger" v-show="state.errors.password">{{ state.errors.password }}</div>
                                </div>
                                <div class="col-6">
                                    <label for="editConfirm" class="form-label">Bekräfta lösenord</label>
                                    <input id="editConfirm" type="password" class="form-control" v-model="state.edit.confirm" />
                                    <div class="text-danger" v-show="state.errors.confirm">{{ state.errors.confirm }}</div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-6">
                                    <label for="editName" class="form-label">Namn</label>
                                    <input id="editName" type="text" class="form-control" v-model="state.edit.name" />
                                    <div class="text-danger" v-show="state.errors.name">{{ state.errors.name }}</div>
                                </div>
                                <div class="col-6">
                                    <label for="editEmail" class="form-label">e-postadress</label>
                                    <input id="editEmail" type="text" class="form-control" v-model="state.edit.email" />
                                    <div class="text-danger" v-show="state.errors.email">{{ state.errors.email }}</div>
                                </div>
                            </div>
                            <div class="form-check mt-3" v-show="state.edit.id">
                                <input id="editLocked" type="checkbox" class="form-check-input" v-model="state.edit.locked"/>
                                <label for="editLocked" class="form-label">Låst</label>
                            </div>
                        </form>
                        <div v-show="state.errorMessage" class="text-danger mt-3">{{ state.errorMessage }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="editModal.hide()">Avbryt</button>
                        <button type="button" class="btn btn-primary" @click="submitEditModal" :disabled="state.editClicked">Spara</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import tokenService from '../services/tokenService'
import usersService from '@/services/usersService'
import { Modal } from 'bootstrap'

let editModal = ref(null)

const state = reactive({
    loggedInUser: null,
    editClicked: false,
    users: null,
    edit: {
        id: null, 
        level: null,
        locked: false,
        password: null,
        confirm: null,
        username: null,
        name: null,
        email: null,
    },
    errors: {
        username: null,
        name: null,
        email: null,
        password: null,
        confirm: null,
    },
    errorMessage: null,
})

async function reload() {
    const users = (await usersService.getUsers()).data
    state.users = users
}

function deleteUser(id) {
    if (confirm('Ta bort användaren?')) {
        usersService.deleteUser(id)
        reload()
    }
}

async function showEditModal(userId) {
    state.errors.username = null
    state.errors.name = null
    state.errors.email = null
    state.errors.password = null
    state.errors.confirm = null
    state.errorMessage = null
    if (userId) {
        const user = state.users.find(u => u.id == userId)
        state.edit.id = userId
        state.edit.level = user.level
        state.edit.locked = user.is_locked
        state.edit.name = user.name
        state.edit.email = user.email
        state.edit.username = user.username
        state.edit.password = null
        state.edit.confirm = null
    } else {
        state.edit.id = null
        state.edit.level = null
        state.edit.locked = false
        state.edit.name = null
        state.edit.email = null
        state.edit.username = null
        state.edit.password = null
        state.edit.confirm = null
    }
    editModal.show()
}

async function submitEditModal() {
    state.editClicked = true

    state.errors.username = validadeUsername(state.edit.username)
    state.errors.name = state.edit.name.trim() ? null : 'Namn saknas.'
    state.errors.email = validateEmail(state.edit.email)
    if (!state.edit.id || state.edit.password || state.edit.confirm) {
        state.errors.password = validatePassword(state.edit.password)
        state.errors.confirm = state.edit.password !== state.edit.confirm ? 'Lösenorden matchar inte.' : null
    } else {
        state.errors.password = null
        state.errors.confirm = null
    }
    state.errorMessage = null

    if (state.errors.username || state.errors.name || state.errors.email || state.errors.password || state.errors.confirm) {
        state.editClicked = false
        return
    }

    if (state.edit.id) {
        try {
            await usersService.updateUser(state.edit)
            editModal.hide()
            reload()
        } catch(err) {
            if (err.code === 'ERR_NETWORK') {
                state.errorMessage = 'Nätverksfel, vänligen kontrollera din internetanslutning.'
                return
            }
            state.errorMessage = err.response.data
        }
    } else {
        try {
            await usersService.createUser(state.edit)
            editModal.hide()
            reload()
        } catch(err) {
            if (err.code === 'ERR_NETWORK') {
                state.errorMessage = 'Nätverksfel, vänligen kontrollera din internetanslutning.'
                return
            }
            state.errorMessage = err.response.data
        }
    }
    state.editClicked = false
}

function validadeUsername(username) {
    const errors = []

    if (username.trim() !== username) {
        errors.push('Användarnamnet får inte börja eller sluta med blanktecken.')
    }
    if (username.length < 3) {
        errors.push('Användarnamnet måste vara minst 3 tecken långt.')
    }
    if (username.length > 30) {
        errors.push('Användarnamnet får inte vara längre än 30 tecken.')
    }

    return errors.length > 0 ? errors.join(' ') : null
}

function validateEmail(email) {
    const valid = String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

    return valid ? null : 'Ogiltig e-postadress.'
}

function validatePassword(password) {
    const errors = []

    if (password.length < 8) {
        errors.push('Lösenordet måste vara minst 8 tecken långt.')
    }
    if (!/\d/.test(password)) {
        errors.push('Det behövs minst en siffra.')
    }
    if (!/[A-ZÅÄÖ]/.test(password)) {
        errors.push('Det behövs minst en stor bokstav.')
    }
    if (!/[a-zåäö]/.test(password)) {
        errors.push('Det behövs minst en liten bokstav.')
    }
    if (!/[^A-Za-z0-9ÅÄÖåäö]/.test(password)) {
        errors.push('Det behövs minst ett tecken annat än en siffra eller bokstav.')
    }

    return errors.length > 0 ? errors.join(' ') : null
}

onMounted(async () => {
    state.loggedInUser = await tokenService.getUser()
    editModal = new Modal(document.getElementById('editModal'))
    reload()
})

onBeforeRouteLeave((to, from) => {
    editModal.hide()
})
</script>