<template>
    <div class="modal" id="importModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Importera bokningar</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-info">
                        Importera bokningar från Excel-fil (.xlsx).<br/>
                        Följande kolumner måste finnas: Bokningsnr., Datum, Lucka<br/>
                        Kolumnen Platsadress kan finnas, men kommer inte att användas.<br/>
                        Lucka skall vara ett heltal mellan 1 och 4.
                    </div>
                    <form>
                        <div class="mb-3">
                            <label for="fileInput" class="form-label">Välj fil</label>
                            <input ref="fileInput" class="form-control" type="file" id="fileInput" :key="data.fileInputKey"/>
                        </div>
                        <div>
                            <input id="allowOverbookingCheckbox" class="form-check-input" type="checkbox" v-model="allowOverbooking"/>
                            <label for="allowOverbookingCheckbox" class="form-check-label">&nbsp;Tillåt överbokningar</label>
                        </div>
                        <div class="mt-3">
                            <input id="overwriteExistingCheckbox" class="form-check-input" type="checkbox" v-model="overwriteExisting"/>
                            <label for="overwriteExistingCheckbox" class="form-check-label">&nbsp;Skriv över befintliga bokningar</label>
                        </div>
                        <div v-if="overwriteExisting" class="alert alert-danger mt-3">
                            <strong>Varning!</strong> Om bokningar redan aviserats så kan de nu få en ny tid i systemet.
                        </div>
                    </form>
                    <div v-show="data.successMessage" class="text-success mt-3">
                        {{ data.successMessage }}
                    </div>
                    <div v-show="data.errorMessage" class="text-danger mt-3">
                        {{ data.errorMessage }}
                        <ul>
                            <li v-for="error in data.errorLines">{{ error }}</li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">{{ data.successMessage ? 'Stäng' : 'Avbryt' }}</button>
                    <button type="button" class="btn btn-primary" v-show="!data.successMessage" @click.once="onSubmit" :key="data.fileInputKey">Importera</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import readXlsxFile from 'read-excel-file'
import projectsService from '@/services/projectsService'

const props = defineProps({
    data: Object
})

const emit = defineEmits([ 'close', 'reload' ])

const fileInput = ref(null)
const overwriteExisting = ref(false)
const allowOverbooking = ref(false)
const route = useRoute()

async function onSubmit() {
    props.data.errorMessage = null
    props.data.errorLines = []
    props.data.successMessage = null

    const file = fileInput.value.files[0]

    if (!file) {
        props.data.errorMessage = 'Ingen fil vald'
        props.data.fileInputKey++
        return
    }

    const reader = new FileReader()
    let content = null

    reader.onload = res => {
        content = res.target.result
    }
    reader.onerror = () => {
        props.data.errorMessage = 'Fel vid inläsning av filen'
    }
    reader.onloadend = async () => {
        try {
            const rows = await readXlsxFile(content)

            if (rows.length === 0) {
                props.data.errorMessage = 'Filen är tom'
                props.data.fileInputKey++
                return
            }

            const headers = {}
            let headersCount = 0
            for (let i = 0; i < rows[0].length; i++) {
                headers[rows[0][i]] = i
                headersCount++
            }

            if (headers['Bokningsnr.'] == null || headers['Datum'] == null || headers['Lucka'] == null) {
                props.data.errorMessage = 'Obligatoriska kolumner saknas'
                props.data.fileInputKey++
                return
            }

            const bookings = []

            let rowNumber = 1
            for (let row of rows) {
                if (rowNumber === 1) {
                    rowNumber++
                    continue
                }
                bookings.push({
                    row: rowNumber++,
                    number: row[headers['Bokningsnr.']],
                    date: row[headers['Datum']],
                    index: isNaN(row[headers['Lucka']]) ? null : parseInt(row[headers['Lucka']]) - 1
                })
            }

            if (bookings.length === 0) {
                props.data.errorMessage = 'Filen är tom förutom rubrikraden'
                props.data.fileInputKey++
                return
            }

            const importResult = (await projectsService.updateJobsTimeWindow(route.params.id, { 
                jobs: bookings,
                overwrite: overwriteExisting.value,
                overbook: allowOverbooking.value
            })).data

            props.data.successMessage = 'Bokade ' + importResult.count + ' jobb'

            if (importResult.count > 0) {
                emit('reload')
            }
        } catch (error) {
            props.data.fileInputKey++
            if (error.response && error.response.status === 400) {
                const errors = []
                for (let row of error.response.data) {
                    errors.push('Rad ' + row.row + ': ' + row.message)
                }
                props.data.errorLines = errors
                props.data.errorMessage = 'Det fanns fel i filen:'
            } else {
                console.log(error)
                props.data.errorLines = null
                props.data.errorMessage = 'Annat fel'
            }
        }
    }
    reader.readAsArrayBuffer(file)
}
</script>