const monthNames = [ 'jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec' ]
const MINUTE_MS = 1000 * 60
const HOUR_MS = MINUTE_MS * 60
const DAY_MS = HOUR_MS * 24
const MONTH_MS = DAY_MS * 30
const WEEK_MS = DAY_MS * 7
const YEAR_MS = DAY_MS * 365
const MS_A_DAY = 1000 * 60 * 60 * 24

class DateTime {
    getDateString(date) {
        const yyyy = date.getFullYear()
        const mm = (date.getMonth() + 1).toString().padStart(2, '0')
        const dd = date.getDate().toString().padStart(2, '0')
        return `${yyyy}-${mm}-${dd}`
    }

    getDateTimeString(date, options = { dateOnly: false, seconds: false, timeOnly: false, separateWithT: false }) {
        if (typeof date === 'undefined') {
            return ''
        }
        let d = date
        if (typeof date === 'string') {
            d = new Date(date)

            if (isNaN(d)) {
                return ''
            }
        }

        const yyyy = d.getFullYear()
        const MM = (d.getMonth() + 1).toString().padStart(2, '0')
        const dd = d.getDate().toString().padStart(2, '0')
        if (options.dateOnly) {
            return `${yyyy}-${mm}-${dd}`
        }
        const hh = d.getHours().toString().padStart(2, '0')
        const mm = d.getMinutes().toString().padStart(2, '0')
        const ss = d.getSeconds().toString().padStart(2, '0')
        if (options.timeOnly) {
            return `${hh}:${mm}:${ss}`
        }
        if (options.seconds) {
            return `${yyyy}-${MM}-${dd}${options.separateWithT ? 'T' : ' '}${hh}:${mm}:${ss}`
        }
        return `${yyyy}-${MM}-${dd}${options.separateWithT ? 'T' : ' '}${hh}:${mm}`
    }

    add(date, interval, count) {
        let year = date.getFullYear()
        let month = date.getMonth()
        let day = date.getDate()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        let millis = date.getMilliseconds()

        switch (interval) {
            case 'year': year += count; break
            case 'month': month += count; break
            case 'week': day += count * 7; break
            case 'day': day += count; break
            case 'hour': hours += count; break
            case '15min': minutes += count * 15; break
            default: throw 'Unknown interval add'
        }

        return new Date(year, month, day, hours, minutes, seconds, millis)
    }

    floor(date, interval) {
        switch (interval) {
            case '5year':
                return new Date(date.getFullYear() - 4, 1, 1)
            case 'year':
                return new Date(date.getFullYear(), 0, 1)
            case 'month':
                return new Date(date.getFullYear(), date.getMonth(), 1)
            case 'week': {
                let day = date.getDay() - 1
                if (day < 0) {
                    day += 7
                }
                return this.add(date, 'day', -day)
            }
            case 'day':
                return new Date(date.getFullYear(), date.getMonth(), date.getDate())
            case 'hour':
                return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours())
            case '15min': {
                let minutes = Math.floor(date.getMinutes() / 15) * 15
                return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), minutes)
            }
            default:
                throw 'Unknown interval floor ' + interval
        }
    }

    ceil(date, interval) {
        switch (interval) {
            case 'year':
                return new Date(date.getFullYear() + 1, 1, 1)
            case 'month':
                return new Date(date.getFullYear(), date.getMonth() + 1, 1)
            case 'week': {
                let day = date.getDay() - 1
                if (day < 0) {
                    day += 7
                }
                return this.add(date, 'day', 7 - day)
            }
            case 'day':
                return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
            case 'hour':
                return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + 1)
            case '15min': {
                let minutes = (Math.floor(date.getMinutes() / 15) + 1) * 15
                return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), minutes)
            }
            default:
                throw 'Unknown interval ceil'
        }
    }

    getWeekNumber(date) {
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay()||7))
        var yearStart = new Date(Date.UTC(date.getUTCFullYear(),0,1))
        var weekNo = Math.ceil(( ( (date - yearStart) / MS_A_DAY) + 1)/7)
        return weekNo
    }

    getWeeksInYear(year) {
        const week = this.getWeekNumber(new Date(year, 11, 31))
        return week == 1 ? 52 : week
    }

    getMonday(year, week) {
        const jan4 = new Date(year, 0, 4)
        const date = this.add(jan4, 'day', (week - 1) * 7)
        const day = (date.getDay() + 6) % 7
        return this.add(date, 'day', -day)
    }

    getMonthYearString(year, month) {
        return `${monthNames[month]} ${year}`
    }

    getMonthString(month) {
        return monthNames[month]
    }

    getString(date, interval) {
        const year = date.getFullYear()
        if (interval === '15min' || interval === 'hour') {
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            const hour = date.getHours().toString().padStart(2, '0')
            const minute = date.getMinutes().toString().padStart(2, '0')
            return `${year}-${month}-${day} ${hour}:${minute}`
        } else if (interval === 'day') {
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            return `${year}-${month}-${day}`
        } else if (interval === 'week') {
            const week = this.getWeekNumber(date)
            return `${year} v${week}`
        } else if (interval === 'month') {
            const month = monthNames[date.getMonth()]
            return `${month} ${year}`
        } else if (interval === 'year') {
            return `${year}`
        }
    }

    durationText(from, to) {
        const diff = Math.abs(from.getTime() - to.getTime())

        if (diff > YEAR_MS) {
            let years = Math.floor(diff / YEAR_MS)
            return `Mer än ${years} år sedan.`
        }

        if (diff > MONTH_MS) {
            let months = Math.floor(diff / MONTH_MS)
            const unit = months > 1 ? 'månader' : 'månad'
            return `Mer än ${months} ${unit} sedan.`
        }

        if (diff > WEEK_MS) {
            let weeks = Math.floor(diff / WEEK_MS)
            const unit = weeks > 1 ? 'veckor' : 'vecka'
            return `Mer än ${weeks} ${unit} sedan.`
        }

        if (diff > DAY_MS) {
            let days = Math.floor(diff / DAY_MS)
            const unit = days > 1 ? 'dagar' : 'dag'
            return `${days} ${unit} sedan.`
        }

        if (diff > HOUR_MS) {
            let hours = Math.floor(diff / HOUR_MS)
            const unit = hours > 1 ? 'timmar' : 'timme'
            return `${hours} ${unit} sedan.`
        }

        if (diff > MINUTE_MS) {
            let minutes = Math.floor(diff / MINUTE_MS)
            const unit = minutes > 1 ? 'minuter' : 'minut'
            return `${minutes} ${unit} sedan.`
        }

        return '<1 minut sedan.'
    }
}

export default new DateTime()