<template>
    <div class="modal modal-lg" id="jobLogModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Jobblogg</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Tidpunkt</th>
                                <th>Händelse</th>
                                <th>Kommentar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="entry in props.data.entries">
                                <tr>
                                    <td><button class="btn btn-outline-secondary" :title="entry.expanded ? 'Dölj data' : 'Visa data'" @click="entry.expanded = !entry.expanded" >{{ entry.expanded ? '-' : '+' }}</button></td>
                                    <td>{{ new Date(entry.timestamp).toLocaleDateString('sv') + ' ' + new Date(entry.timestamp).toLocaleTimeString('sv')  }}</td>
                                    <td>{{ NAMES[entry.name] ?? 'Okänt' }}</td>
                                    <td>{{ entry.comment }}</td>
                                </tr>
                                <tr v-show="entry.expanded">
                                    <td colspan="4">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr v-for="item in entry.data">
                                                    <td>{{ item.property }}</td>
                                                    <td>{{ item.value }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Stäng</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const emit = defineEmits([ 'close' ])

const props = defineProps({
    data: Object
})

const NAMES = {
    'contact_request': 'Kontaktönskan',
    'login': 'Inloggning',
    'rebook': 'Ombokning',
    'updated_contact': 'Uppdaterad kontakt',
    'send_confirmation': 'Skickat bekräftelse',
    'cancelled_admin': 'Avbokad av admin',
    'rebook_admin': 'Ombokad av admin',
    'updated_contact_admin': 'Uppdaterad kontakt av admin',
    'failed_run': 'Bomkörning',
    'job_completed': 'Jobb utfört',
    'job_started': 'Jobb påbörjat',
    'job_reopened': 'Jobb återöppnat',
    'comment': 'Kommentar',
}

</script>

<style scoped>
.arrowExpanded:after,
.arrowCollapsed:after {
  content: ' ';
  position: relative;
  left: 2px;
  border: 8px solid transparent;
}

.arrowExpanded:after {
  top: 10px;
  border-top-color: silver;
}

.arrowCollapsed:after {
  top: 15px;
  border-bottom-color: silver;
}

.arrowExpanded,
.arrowCollapsed {
  padding-right: 10px;
}
</style>