<template>
    <div class="modal" id="importModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Importera tidsluckor</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-info">
                        Importera tidsluckor från Excel-fil (.xlsx)<br/>
                        Följande kolumner måste finnas: Datum, Lucka, Antal<br/>
                        Lucka skall vara ett heltal mellan 1 och 4.<br/>
                    </div>
                    <form>
                        <div class="mb-3">
                            <label for="fileInput" class="form-label">Välj fil</label>
                            <input ref="fileInput" class="form-control" type="file" id="fileInput" :key="data.fileInputKey"/>
                        </div>
                    </form>
                    <div v-show="data.errorMessage" class="text-danger">
                        {{ data.errorMessage }}
                    </div>
                    <div v-show="data.successMessage" class="text-success">
                        {{ data.successMessage }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">{{ data.successMessage ? 'Stäng' : 'Avbryt' }}</button>
                    <button type="button" class="btn btn-primary" v-show="!data.successMessage" @click.once="onSubmit" :key="data.fileInputKey">Importera</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import readXlsxFile from 'read-excel-file'
import projectsService from '@/services/projectsService'

const props = defineProps({
    data: Object
})

const emit = defineEmits([ 'close', 'reload' ])

const fileInput = ref(null)
const route = useRoute()

async function onSubmit() {
    props.data.errorMessage = null
    props.data.successMessage = null

    const file = fileInput.value.files[0]

    if (!file) {
        props.data.errorMessage = 'Ingen fil vald'
        props.data.fileInputKey++
        return
    }

    const reader = new FileReader()
    let content = null

    reader.onload = res => {
        content = res.target.result
    }
    reader.onerror = () => {
        props.data.errorMessage = 'Fel vid inläsning av filen'
    }
    reader.onloadend = async () => {
        try {
            const rows = await readXlsxFile(content)

            if (rows.length === 0) {
                props.data.errorMessage = 'Inga rader hittades i filen'
                return
            }

            const headers = {}

            for (let i = 0; i < rows[0].length; i++) {
                headers[rows[0][i]] = i
            }

            if (headers['Datum'] == null || headers['Lucka'] == null || headers['Antal'] == null) {
                props.data.errorMessage = 'Obligatoriska kolumner saknas'
                return
            }

            const timeWindows = []

            let firstRow = true
            for (let row of rows) {
                if (firstRow) {
                    firstRow = false
                    continue
                }
                const timeWindow = {
                    date: row[headers['Datum']],
                    index: parseInt(row[headers['Lucka']]) - 1,
                    spots: row[headers['Antal']],
                }
                timeWindows.push(timeWindow)
            }

            const importResult = (await projectsService.createTimeWindows(route.params.id, timeWindows)).data

            props.data.successMessage = 'Importerade ' + importResult.count + ' tidsluckor'

            if (importResult.count > 0) {
                emit('reload')
            }
        } catch (error) {
            props.data.fileInputKey++
            if (error.response && error.response.data) {
                props.data.errorMessage = error.response.data.message ?? (error.response.status + ' ' + error.response.statusText)
            } else {
                console.log(error)
                props.data.errorMessage = 'Annat fel'
            }
        }
    }
    reader.readAsArrayBuffer(file)
}
</script>